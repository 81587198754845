// Generated by Framer (e1022ef)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["NsHWPDBlP"];

const serializationHash = "framer-KZHJG"

const variantClassNames = {NsHWPDBlP: "framer-v-swpr8z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, label, width, ...props}) => { return {...props, nkAhZWSGr: label ?? props.nkAhZWSGr ?? "CHIP LABEL"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;label?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nkAhZWSGr, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "NsHWPDBlP", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-swpr8z", className, classNames)} data-framer-name={"Chip"} layoutDependency={layoutDependency} layoutId={"NsHWPDBlP"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(238, 236, 255)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7U29yYS01MDA=", "--framer-font-family": "\"Sora\", \"Sora Placeholder\", sans-serif", "--framer-font-size": "12px", "--framer-font-weight": "500", "--framer-letter-spacing": "1px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(80, 80, 255))"}}>CHIP LABEL</motion.p></React.Fragment>} className={"framer-1arzr32"} data-framer-name={"Launching Q4 2022"} fonts={["GF;Sora-500"]} layoutDependency={layoutDependency} layoutId={"bXBy8Gf67"} style={{"--extracted-r6o4lv": "rgb(80, 80, 255)"}} text={nkAhZWSGr} verticalAlignment={"bottom"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-KZHJG[data-border=\"true\"]::after, .framer-KZHJG [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-KZHJG.framer-ajyn90, .framer-KZHJG .framer-ajyn90 { display: block; }", ".framer-KZHJG.framer-swpr8z { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 5px 12px 5px 12px; position: relative; width: min-content; }", ".framer-KZHJG .framer-1arzr32 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-KZHJG.framer-swpr8z { gap: 0px; } .framer-KZHJG.framer-swpr8z > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-KZHJG.framer-swpr8z > :first-child { margin-left: 0px; } .framer-KZHJG.framer-swpr8z > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 105
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"nkAhZWSGr":"label"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerEkbnfcwYe: React.ComponentType<Props> = withCSS(Component, css, "framer-KZHJG") as typeof Component;
export default FramerEkbnfcwYe;

FramerEkbnfcwYe.displayName = "Chip";

FramerEkbnfcwYe.defaultProps = {height: 24, width: 105};

addPropertyControls(FramerEkbnfcwYe, {nkAhZWSGr: {defaultValue: "CHIP LABEL", displayTextArea: false, title: "Label", type: ControlType.String}} as any)

addFonts(FramerEkbnfcwYe, [{family: "Sora", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdgnn-PIwNhBti0.woff2", weight: "500"}])